@import "/src/assets/theme/color.scss";
@import "/src/assets/theme/variables.scss";
@import "/src/assets/theme/font-sizes.scss";

.whiteBg {
  background-color: white;
  .contact-component {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    padding: 50px 100px;
    min-height: 80vh;

    @media screen and (max-width: $mobile_breakpoints) {
      padding: 50px 50px;
    }

    .introduction-section {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      flex-direction: row-reverse;
      justify-content: center;
      align-items: center;
      gap: 5%;
      align-items: flex-start;

      .contact-us-title {
        display: flex;
        flex-flow: row nowrap;
        color: $primary-color-dark;
        font-weight: 600;
        margin-bottom: 10%;
        @include font-size45();
      }

      @media screen and (max-width: $mobile_breakpoints) {
        flex-flow: column-reverse nowrap;
      }

      .form {
        width: 50%;
        @media screen and (max-width: $mobile_breakpoints) {
          width: 100%;
        }
        .title {
          color: $white;
          @include font-size25();
          @media screen and (max-width: $mobile_breakpoints) {
            color: $primary-color-dark;
          }
        }

        .name-fields {
          display: flex;
          gap: 5%;
          margin: 2% 0 2% 0;
          .field-size {
            width: 100%;
          }

          @media screen and (max-width: $mobile_breakpoints) {
            flex-direction: column;
          }
        }

        .email-phone {
          display: flex;
          gap: 5%;
          margin: 2% 0 2% 0;
          .field-size {
            width: 100%;
          }

          @media screen and (max-width: $mobile_breakpoints) {
            flex-direction: column;
          }
        }
        .message {
          .field-label {
            margin: 0 0 2% 0;
          }
        }
      }
      .image {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        width: 38%;
        @media screen and (min-width: 1500px) {
          width: 27%;
        }
        img{
          width: 100%; 
          }
        @media screen and (max-width: $mobile_breakpoints) {
          width: 100%;
          margin-bottom: 20px;
        }
      }
    }
  }
  .form-footer-elements {
    margin-top: 5%;
    .send-button {
      border-radius: 8px;
    }
  }
}
