@import "/src/assets/theme/color.scss";
@import "/src/assets/theme/font-sizes.scss";
@import "/src/assets/theme/variables.scss";

.total-section {
  display: flex;
  justify-content: flex-end;
  font-weight: 700;
}
.step-checkout-label {
  span {
    @include font-size25();
  }
}

.stepper-checkout {
  margin-top: 40px;
  overflow-x: auto;
}
.stepper-checkout::-webkit-scrollbar {
  display: none;
}
.check-information {
  display: flex;
  justify-content: flex-end;
}

.content {
  padding-bottom: 20px;
}

.plan-options-packages {
  display: flex;
  justify-content: center;
  .radio-row {
    display: flex;
  }
  .plan-package-options-font-style {
    @include font-size20();
  }
}
.upgrade-now-section {
  display: flex;
  margin: 20px 0px;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  @include md-screen {
    flex-direction: column;
  }
}
