@import "/src/assets/theme/color.scss";
@import "/src/assets/theme/variables.scss";
@import "/src/assets/theme/font-sizes.scss";

.hintText {
  @include font-size18();
  padding: 5px;
}
.hint-popper-button {
  padding: 8px 2px !important;
}
.hint-popper {
  width: 30%;

  @include sm-screen {
    width: 60%;
  }
}
