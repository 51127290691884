@import "/src/assets/theme/color.scss";
@import "/src/assets/theme/variables.scss";
@import "/src/assets/theme/font-sizes.scss";

.no-data-found {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  margin-top: 20px;

  color: $primary-color-light;
  font-weight: 700;
}

.no-debt-found {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  @include font-size35();
  color: $primary-color-dark;
}
