@import "/src/assets/theme/color.scss";
@import "/src/assets/theme/variables.scss";
@import "/src/assets/theme/media-queries.scss";
@import "/src/assets/theme/font-sizes.scss";

.login-form-container {
  width: 40%;
  min-height: 300px;
  min-width: 300px;
  max-width: 500px;
  border-radius: $authentication-border-radius !important;
  margin: auto;
  box-shadow: $login-shadow !important;
  background-color: $login-bg !important;
  display: flex;

  @include md-screen {
    width: 60%;
  }
  @include sm-screen {
    width: 70%;
  }

  @include xsm-screen {
    width: 80%;
  }

  .content {
    width: 100%;
    .main-title {
      margin-bottom: 3%;
    }
  }

  .form-container {
    width: 80%;
    margin: auto;

    @include sm-screen {
      width: 100%;
    }
  }
  .login-action {
    width: 100%;
    margin: 0 auto;
    .login-button {
      border-radius: 8px;
    }
  }
  .account-question {
    text-align: center;
    margin: 10px 0px;
    .action {
      cursor: pointer;
      font-weight: 600;
    }
  }

  .forgot-password-space {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    .forgot-button {
      font-weight: 600;
      width: fit-content !important;
      height: 30px;
      margin-bottom: 10px;
    }
  }
}
