@import "/src/assets/theme/color.scss";
@import "/src/assets/theme/font-sizes.scss";
@import "/src/assets/theme/variables.scss";

.form-title {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  @include font-size45();
}
.page-title {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  color: $primary-color-dark;
  margin-bottom: 15px;

  .form-title {
    font-weight: 600;
    @include font-size45();
  }
  .start {
    display: flex;

    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    font-weight: bold;

    .icon-space {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
    }
    .title-text {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
.plan-title {
  @include font-size35();
  font-weight: 700;
  margin-bottom: 3%;
  color: $primary-color-dark;
}
.mini-title {
  @include font-size18();
  color: $secondary-color !important;
}
.main-title {
  color: $primary-color-dark;
  @include font-size45();
}

.title-35 {
  @include font-size35();
}
.sub-title {
  @include font-size25();
}

.header-section {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 25%;

  @media screen and (max-width: $mobile_breakpoints) {
    gap: 15%;
  }

  .search-bar-section {
    width: 30%;
    min-width: 120px;

    @media screen and (max-width: $mobile_breakpoints) {
      width: 100%;
      margin-bottom: 5px;
    }
  }

  .button-section {
    display: flex;
    // width: 30%;
    flex: 1;
    justify-content: flex-end;
    gap: 15px;

    @media screen and (max-width: $mobile_breakpoints) {
      width: 100%;
      margin-bottom: 20px;
    }
  }
}
