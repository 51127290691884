@import "/src/assets/theme/color.scss";
@import "/src/assets/theme/variables.scss";

.loader-component {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  margin-top: 20px;
}

.image-skeleton {
  display: flex;
  flex-flow: row wrap;
  gap: 2%;
  row-gap: 1em;
}
.plans-skeleton {
  display: flex;
  max-height: 600px;
  gap: 5%;
  align-items: flex-start;
  justify-content: center;
  height: 100%;

  .plan-item {
    border-radius: 5px;
  }
}
