@import "/src/assets/theme/color.scss";
@import "/src/assets/theme/variables.scss";
@import "/src/assets/theme/font-sizes.scss";

.subscription-body-container {
  display: flex;
  flex-flow: row wrap;
  margin-top: 10px;
  max-width: 100%;

  .subscriptionCardSection {
    margin-top: 40px !important;
  }
  .subscriptionCardHeader {
    color: white;
    background-color: #0052a2;
    text-align: center;
  }

  .subscriptionCard {
    // width: 300px;
    margin-right: 5px;
  }

  .priceRow {
    color: #0052a2;
    font-size: 30px;
  }
}
.upgrade-attention-message {
  margin: 10px 0px;
  @include font-size16();
  color: $primary-color-dark;
}

.auto-renew-cancel-message {
  color: $primary-color-dark;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.subscriptions {
  margin: auto;
  .header-icon {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    width: 45%;
    .icon {
      background-color: #e6e8ea;
      border-radius: 50%;
      @include font-size35();
      width: 50px;
      height: 50px;
      padding: 10px;
      cursor: pointer;
    }
  }
  .swiper {
    width: 100%;
    height: 100%;
    padding: 0 3% 3% 3%;
  }

  .swiper-button-next {
    background-image: url(../../../assets/svg/swiper_arrow.svg);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    width: 1%;
    @media screen and (max-width: 900px) {
      width: 1.5%;
    }
    @media screen and (max-width: 700px) {
      width: 1.8%;
    }
    @media screen and (max-width: 600px) {
      width: 2%;
    }
    @media screen and (max-width: 400px) {
      width: 3%;
    }
  }

  .swiper-button-next::after {
    display: none;
  }

  .swiper-button-prev {
    background-image: url(../../../assets/svg/swiper_arrow.svg);
    transform: rotateY(180deg);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    width: 1%;

    @include md-screen {
      width: 1.5%;
    }

    @include sm-screen {
      width: 2%;
    }

    @media screen and (max-width: 700px) {
      width: 1.8%;
    }

    @media screen and (max-width: 400px) {
      width: 3%;
    }
  }

  /* Change arrow size */
  .swiper-button-prev::after,
  .swiper-button-next::after {
    width: 30px; /* Change to your desired width */
    height: 30px; /* Change to your desired height */
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    display: none;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.subscription-form-Details {
  width: 100%;
  margin-top: 3%;
  padding-bottom: 3%;
  .portal-form-container {
    width: 100%;
    border-radius: 10px !important;
    margin: auto;
    box-shadow: $subscription-shadow !important;
    margin-top: 3%;
    display: flex;

    .portal-form-content {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;
      padding: 3%;

      .autorenew-disclaimer {
        border: 1px solid $primary-color-dark;
        border-radius: 5px;
        padding: 10px;
        :nth-child(1) {
          font-weight: 700;
          text-decoration: underline;
        }
      }

      .detail-footer {
        width: 100%;
        display: flex;
        @include sm-screen {
          flex-direction: column;
          gap: 10px;
          align-items: stretch;
        }

        justify-content: space-between;
        align-items: center;
        .renew {
          display: flex;
          align-items: center;
          gap: 10px;
          .auto {
            position: relative;
            @include font-size16();
            color: $primary-color-dark;
            font-weight: 600;
          }
        }
        .action-buttons {
          display: flex;
          flex: 1 1 300px;
          max-width: 30%;
          gap: 5%;
          @include sm-screen {
            width: 100%;
          }
          .save-button {
            padding: 0 40px 0 40px;
            @include font-size18();
            @include sm-screen {
              width: 100%;
            }
          }
        }
        .renew-enabled {
          display: flex;
          gap: 5%;
          width: 50%;
          justify-content: flex-end;
          @include sm-screen {
            width: 100%;
          }
        }
      }
      .renew-enabled-footer {
        justify-content: flex-end;
      }
    }
  }
}

.trial-button {
  width: 45%;
  margin: auto;
}

.renew-buttons {
  justify-content: center !important;
}
.go-buttons {
  width: 50%;
  margin: auto;
}

.upgrade-now-section {
  display: flex;
  margin: 20px 0px;
  align-items: center;
  justify-content: space-between;

  @include md-screen {
    flex-direction: column;
  }
}

.subscription-detail-content {
  display: flex;
  flex-flow: row nowrap;

  @include md-screen {
    flex-flow: column nowrap;
  }

  .section {
    width: 50%;

    display: flex;
    flex-flow: column nowrap;
    @include md-screen {
      width: 100%;
    }

    .section-progress {
      width: 100%;
    }

    &.left-section {
      border-right: 1px solid $primary-color-dark;
      padding-right: 10px;
      @include md-screen {
        border-right: unset;
        padding-right: unset;
      }
    }
    &.right-section {
      padding-left: 10px;
      @include md-screen {
        padding-left: unset;
      }
    }

    .section-item {
      display: flex;
      flex-flow: row nowrap;
      color: $primary-color-dark;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      @include sm-screen {
        flex-flow: column nowrap;
        justify-content: center;
        align-items: flex-start;
        gap: 5px;
      }

      .label {
        width: 50%;
        font-weight: 700;
      }
      .value {
        flex: 1 1 0px;
        text-align: right;
        word-break: break-all;

        @include sm-screen {
          text-align: left;
        }
      }
    }
  }
}
