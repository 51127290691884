@import "/src/assets/theme/color.scss";
@import "/src/assets/theme/variables.scss";
@import "/src/assets/theme/font-sizes.scss";

.load-more-pagination-section {
  margin: 5px 0px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.table-paper {
  width: 100%;
  overflow: hidden;
  box-shadow: $table-shadow !important;

  .table-container-component {
    min-height: 500px;
    max-height: 600px;

    position: relative;

    .initial-table {
      padding: 10px;
    }
    .button-span {
      padding-bottom: unset !important;
      padding-top: unset !important;
    }
  }
}
.table-pagination-section {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px;
  .form-control-item {
    width: 10%;
    @media screen and (max-width: $mobile_breakpoints) {
      margin: 10px 0px;
      width: 100%;
    }
  }

  @media screen and (max-width: $mobile_breakpoints) {
    flex-flow: column-reverse nowrap;
    align-items: center;
    justify-content: center;
  }
}
