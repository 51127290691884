@import "/src/assets/theme/color.scss";
@import "/src/assets/theme/font-sizes.scss";
@import "/src/assets/theme/variables.scss";

.image-management-container {
  .image-body-container {
    display: flex;
    flex-flow: row wrap;
    gap: 5px;
  }
}

.new-image-error-message {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  color: red;
}
.new-image-actions {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  gap: 5%;
  align-items: center;
}

.new-image-form-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0px 40px;
  .form-other-fields {
    display: flex;
    flex-flow: column nowrap;

    div {
      margin-bottom: 5px;
    }
  }

  .form-upload-fields {
    font-family: sans-serif;
    text-align: center;
    label {
      min-height: 16px;
      color: #9a9a9a;
      font-size: 15px;
    }
    .missing-fields {
      border: 1px solid red;
    }

    .css-1sasres {
      border: 1px solid #0052a2;
      margin-bottom: 10px;
      padding-bottom: 0px;
      padding-top: 10px;
      border-style: dashed;
      &:hover {
        border: 1px solid #0052a2;
        border-style: dashed;
      }
    }

    .css-1wc848c-MuiFormHelperText-root {
      color: red;
    }
    .css-1q8v7ak-MuiSvgIcon-root {
      path {
        color: #0052a2 !important;
      }
    }
  }
}

.images-list-container {
  width: 12px;
}

.category-component-item {
  width: 100%;
  margin-bottom: 10px;

  .element-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    .name {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow-x: hidden;
      width: 70%;
    }
  }
}

.image-component {
  flex-basis: 120px; /* width:120px; */
  display: flex;
  flex-flow: column nowrap;
  row-gap: 1%;
  .image-container {
    width: 120px;
    position: relative;
    .selected-image {
      border: 5px solid $primary-color-light !important;
    }
    img {
      display: block;
      max-width: 100%;
      border: 1px solid $primary-color-light;
      vertical-align: middle;
      border-radius: 9px;
    }

    .image-vector {
      position: absolute;
      bottom: 5px;
      right: 10px;
      cursor: pointer;
      &.checked {
        rotate: -90deg;
      }
    }
  }
  .createdby-username {
    width: 120px;
    color: $secondary-color;
    @include font-size16();
    word-wrap: break-word;
    margin: 2px 0px;
    text-align: center;
    .admin-name {
      position: relative;
    }
    .admin-start {
      position: absolute;
      top: -6px;
    }
  }
}
