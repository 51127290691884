$primary-color-light: #1f609f;
$primary-color-dark: #00172d;
$gradient: linear-gradient(
  180deg,
  #0052a2 0%,
  #4a84bd 26.56%,
  #c1d5e8 66.15%,
  #ffffff 95.83%
);
$primary-color-light-80: #1f609fcc;
$primary-color-light-60: #1f609f99;
$primary-color-light-40: #1f609f66;
$primary-color-light-20: #1f609f33;
$primary-color-light-10: #1f609f1a;
$primary-color-dark-80: #00172dcc;
$primary-color-dark-60: #00172d99;
$primary-color-dark-40: #00172d66;
$primary-color-dark-20: #00172d33;
$primary-color-dark-10: #00172d1a;
$secondary-color: #9a9a9a;
$blue-grey: rgba(0, 23, 45, 0.2);
$dark-primary: #00172d;
$medium-grey: #c7c7c7;
$grey-color-5: #9a9a9a0d;
$dark-blue-primary: #053d75;
$blue-primary: #1f609f;
$light-primary: #f0f5fa;
$light-dark-primary: #00172dc9;
$black: #000000;
$grey: #9a9a9a;
$white: #ffff;
$body-color: #f7f9f9;
$green: #31c2aa;
$red: #c23131;
$light-red: #fff0f0;
$body-color: linear-gradient(
  180deg,
  #0052a2 0%,
  #4a84bd 26.56%,
  #c1d5e8 66.15%,
  #ffffff 95.83%
);

$shadow: 2px 4px 4px 0px #000000;
$login-shadow: 3px 5px 2px -2px rgba(0, 0, 0, 0.2);
$account-shadow: 2px 2px 9px 2px rgba(2, 2, 70, 0.12);
$subscription-shadow: 2px 2px 10px rgba(28, 38, 61, 0.4);
$progress-shadow: 1px 0px 4px rgba(0, 0, 0, 0.2);
$active-button-shadow: 0px 1px 4px rgba(29, 33, 45, 0.2);
$radio-button-shadow: 2px 2px 9px 2px rgba(2, 2, 70, 0.12);
$login-bg: rgba(255, 255, 255, 0.2);
$plan-card-bg: rgba(0, 23, 45, 0.1);
:export {
  primaryColorLight80: $primary-color-light-80;
  primaryColorLight60: $primary-color-light-60;
  primaryColorLight40: $primary-color-light-40;
  primaryColorLight20: $primary-color-light-20;
  primaryColorLight10: $primary-color-light-10;
  primaryColorDark80: $primary-color-dark-80;
  primaryColorDark60: $primary-color-dark-60;
  primaryColorDark40: $primary-color-dark-40;
  primaryColorDark20: $primary-color-dark-20;
  primaryColorDark10: $primary-color-dark-10;
  primaryColorDark: $primary-color-dark;
  primaryColorLight: $primary-color-light;
  mediumGreyColor: $medium-grey;
  greyColor5: $grey-color-5;
  bluePrimary: $blue-primary;
  secondaryColor: $secondary-color;
  secondaryColor: $secondary-color;
  darkBluePrimary: $dark-blue-primary;
  darkPrimaryColor: $dark-primary;
  lightPrimaryColor: $light-primary;
  blackColor: $black;
  greyColor: $grey;
  whiteColor: $white;
  bodyColor: $body-color;
  greenColor: $green;
  redColor: $red;
  lightRedColor: $light-red;
  lightDarkPrimaryColor: $light-dark-primary;
  shadowElement: $shadow;
  loginShadow: $login-shadow;
  accountShadow: $account-shadow;
  progressShadow: $progress-shadow;
  radioButton: $radio-button-shadow;
  subscriptionShadow: $subscription-shadow;
  activeButton: $subscription-shadow;
  loginBg: $login-bg;
  planBg: $plan-card-bg;
  blueGrey: $blue-grey;
}
