@import "/src/assets/theme/color.scss";
@import "/src/assets/theme/variables.scss";
@import "/src/assets/theme/font-sizes.scss";

.home-component {
  display: flex;
  flex-flow: column nowrap;
  padding: 0px 100px;

  @media screen and (max-width: $mobile_breakpoints) {
    padding: 0px 50px;
  }

  .introduction {
    min-height: 200px;
    display: flex;
    flex-flow: row nowrap;

    @media screen and (max-width: $mobile_breakpoints) {
      flex-flow: column-reverse nowrap;
      margin-bottom: 20px;
    }

    .text {
      width: 50%;
      // @include font-size35();
      color: $white;
      // gap: 5%;
      letter-spacing: 0.2px;
      line-height: 55px;
      font-size: 60px !important;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: flex-start;

      .homepageButton {
        border-color: white;
        color: white;
        border-radius: 10px;
        border-width: 2px;

        @media screen and (max-width: $mobile_breakpoints) {
          border-color: $primary-color-dark;
          color: $primary-color-dark;
          border-radius: 10px;
          border-width: 2px;
        }
      }
      @media screen and (max-width: $mobile_breakpoints) {
        @include font-size35();
        width: 100%;
        align-items: center;
        justify-content: center;
        color: $primary-color-dark;
        letter-spacing: 0.2px;
      }
    }
    .image {
      flex: 1 1 0px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: $mobile_breakpoints) {
        justify-content: center;
        margin: 20px;
      }

      img {
        width: 80%;
        // height: 80%;
      }
    }
  }

  .text-introduction {
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 50px;

    @media screen and (max-width: $mobile_breakpoints) {
      flex-flow: column-reverse nowrap;
      margin-bottom: 20px;
    }

    .text {
      width: 75%;
      @include font-size20();

      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      white-space: normal;

      @media screen and (max-width: $mobile_breakpoints) {
        justify-content: center;
        align-items: center;
        width: 100%;
      }

      .title {
        color: $primary-color-dark;
        font-weight: bold;
        @include font-size35();
        margin-bottom: 2px;
      }
      .body {
        width: 100%;
        margin-bottom: 20px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 2%;
      }
    }

    .video {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @media screen and (max-width: $mobile_breakpoints) {
        justify-content: center;
        margin: 20px;
      }
      video {
        // box-shadow: $shadow;
        border-radius: 16px;
        object-fit: none;
      }
    }
  }
}
