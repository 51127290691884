@import "/src/assets/theme/color.scss";
@import "/src/assets/theme/variables.scss";
@import "/src/assets/theme/font-sizes.scss";

.link-button {
  padding: 0px !important;
  background: none !important;
  border: unset !important;
  @include font-size16();
  :focus {
    outline: none !important;
  }
  :focus-within {
    outline: none !important;
  }
  :focus-visible {
    outline: none !important;
  }

  &.underline {
    text-decoration: underline;
  }
}
// .link-button:hover {
//   color: $primary-color-dark !important;
// }

// .link-button:active {
//   color: $primary-color-dark !important;
// }

.left-padding {
  padding: 0px 0px 0px 5px !important;
  width: fit-content !important;
  //   padding: 0px !important;
  @include font-size16();
}

.categoryfield-linkedbutton {
  color: $primary-color-dark !important;
  @include font-size18();
  font-weight: 500;
  text-decoration: underline !important;
}
