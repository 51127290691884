@import "/src/assets/theme/color.scss";
@import "/src/assets/theme/variables.scss";
@import "/src/assets/theme/font-sizes.scss";

@import "/src/assets/theme/color.scss";
@import "/src/assets/theme/variables.scss";
@import "/src/assets/theme/media-queries.scss";
@import "/src/assets/theme/font-sizes.scss";

.signup-container {
  border-radius: $authentication-border-radius !important;
  margin: auto;
  box-shadow: $login-shadow !important;
  background-color: $login-bg !important;
  display: flex;
  width: 50%;
  max-width: 800px;

  @include md-screen {
    width: 70%;
  }

  @include sm-screen {
    width: 80%;
  }
  .content {
    width: 100%;
    min-width: 200px;
    .main-title {
      margin-bottom: 3%;
    }
  }
  .signup-action {
    width: 50%;
    margin: 0 auto;
    .signup-button {
      border-radius: 8px;
    }

    @include sm-screen {
      width: 100%;
    }
  }
  .signup-form {
    width: 95%;
    margin: auto;
    display: flex;
    margin: auto;
    flex-flow: column;
    gap: 5px;

    @include sm-screen {
      width: 100%;
    }
  }
  .account-question {
    text-align: center;
    margin: 10px 0px;
    .action {
      cursor: pointer;
      font-weight: 600;
    }
  }
}

.signupform-inputs-double-children {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px 40px;

  @include sm-screen {
    grid-template-columns: 1fr;
  }
}
