@import "/src/assets/theme/color.scss";
@import "/src/assets/theme/variables.scss";
@import "/src/assets/theme/font-sizes.scss";

.pricing {
  min-height: 80vh;
  margin: auto;
  padding: 5%;
  .main-title {
    margin-bottom: 3%;
    color: white;
  }

  .swiper {
    width: 100%;
    height: 100%;
    padding: 0 3% 3% 3%;
  }

  .swiper-button-next {
    background-image: url(../../../assets/svg/swiper_arrow.svg);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    width: 1.5%;
    @media screen and (max-width: 900px) {
      width: 1.5%;
    }
    @media screen and (max-width: 700px) {
      width: 1.8%;
    }
    @media screen and (max-width: 600px) {
      width: 2%;
    }
    @media screen and (max-width: 400px) {
      width: 3%;
    }
  }

  .swiper-button-next::after {
    display: none;
  }

  .swiper-button-prev {
    background-image: url(../../../assets/svg/swiper_arrow.svg);
    transform: rotateY(180deg);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    width: 1.5%;
    @media screen and (max-width: 900px) {
      width: 1.5%;
    }
    @media screen and (max-width: 700px) {
      width: 1.8%;
    }
    @media screen and (max-width: 600px) {
      width: 2%;
    }
    @media screen and (max-width: 400px) {
      width: 3%;
    }
  }

  /* Change arrow size */
  .swiper-button-prev::after,
  .swiper-button-next::after {
    width: 30px; /* Change to your desired width */
    height: 30px; /* Change to your desired height */
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    display: none;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
