@import "/src/assets/theme/color.scss";
@import "/src/assets/theme/variables.scss";
@import "/src/assets/theme/font-sizes.scss";

.dialog-title-section {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
.delete-without-reason-prompt {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  div:nth-child(1) {
    color: $primary-color-dark;
    @include font-size20();
    text-align: center;
  }

  .delete-footer-style {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    // margin-left: auto;
    margin-top: 5px;
    gap: 1%;
  }
}
