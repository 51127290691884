@import "/src/assets/theme/color.scss";
@import "/src/assets/theme/variables.scss";
@import "/src/assets/theme/media-queries.scss";
@import "/src/assets/theme/font-sizes.scss";

.forgotpassword-container {
  border-radius: $authentication-border-radius !important;
  margin: auto;
  box-shadow: $login-shadow !important;
  background-color: $login-bg !important;
  display: flex;
  width: 50%;
  max-width: 800px;

  @include md-screen {
    width: 70%;
  }

  @include sm-screen {
    width: 80%;
  }
  .content {
    width: 100%;
    min-width: 200px;
    .main-title {
      margin-bottom: 3%;
    }
  }
  .forgotpassword-action {
    width: 100%;
    margin: 0 auto;
    .forgot-button {
      border-radius: 8px;
    }
  }
  .forgot-form {
    width: 70%;
    margin: auto;
    display: flex;
    margin: auto;
    flex-flow: column;
    gap: 5px;

    @include sm-screen {
      width: 90%;
    }
  }
}
