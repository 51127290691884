@import "/src/assets/theme/color.scss";
@import "/src/assets/theme/variables.scss";
@import "/src/assets/theme/font-sizes.scss";

.about-component {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  min-height: 80vh;
  padding: 50px 100px;

  @media screen and (max-width: $mobile_breakpoints) {
    padding: 100px 50px;
  }

  .about-us-title {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    color: $white;
    @include font-size45();
    @media screen and (max-width: $mobile_breakpoints) {
      margin-bottom: 50px;
    }
    @media screen and (max-width: 1400px) {
      margin-bottom: 30px;
    }
  }

  .introduction-section {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    gap: 8%;
    min-height: 80vh;

    @media screen and (max-width: $mobile_breakpoints) {
      flex-flow: column nowrap;
    }

    .image {
      width: 43%;

      @media screen and (max-width: 1700px) {
        height: 420px;
      }
      @media screen and (max-width: 1000px) {
        height: 300px;
      }
      @media screen and (max-width: 900px) {
        height: 100%;
      }

      @media screen and (max-width: $mobile_breakpoints) {
        width: 100%;
        margin-bottom: 20px;
      }
      img {
        width: 100%;
        border-radius: 16px;
        object-fit: cover;
        height: 100%;
        box-shadow: -12px 12px 1px rgba(31, 96, 159, 0.5);
        // box-shadow:rgba(31, 96, 159, 0.5);
      }
    }

    .text {
      flex: 1 1 0px;
      display: flex;
      flex-flow: column nowrap;

      .section {
        margin-bottom: 20px;

        .title {
          margin-bottom: 5px;
          color: $primary-color-dark;
          font-weight: 500;
          @include font-size35();
        }
        .body {
          text-align: justify;
          font-weight: 400;
          @include font-size20();
        }
      }
    }
  }
}
