@import "/src/assets/theme/color.scss";
@import "/src/assets/theme/variables.scss";
@import "/src/assets/theme/font-sizes.scss";

.item-spacing {
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

//LOGGEDIN NAVIGATION BAR
.navigationBar {
  top: 0;
  width: 100%;
  height: 65px;
  position: fixed;
  background: $blue-primary !important;
  z-index: 1000 !important;

  .navigationBar-container {
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;

    max-width: 2000px;
    margin: 0px auto;
  }

  .logo {
    display: flex;
    width: 32%;
    align-items: center;

    flex-flow: row nowrap;
    color: $primary-color-dark;
    gap: 10%;
    font-weight: 500;
    margin: 0 5%;

    img {
      width: 100px;
      height: 30px;
      cursor: pointer;
    }

    @media screen and (max-width: 500px) {
      width: 60%;
    }
  }

  .side-items {
    flex: 1 1 0px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;

    align-items: center;
    gap: 2%;
    margin: 0 5%;
  }
}

.drawer-header-container {
  background-color: $primary-color-light;
  padding: 0px 20px !important;
  padding: 10px;
  .text {
    flex: 1;

    color: $primary-color-dark;
    font-weight: 500;

    img {
      width: 100px;
      height: 30px;
      cursor: pointer;
    }
  }
}

.landing-navigation-bar {
  top: 0;
  width: 100%;
  height: 65px;
  position: fixed;
  background: $blue-primary !important;
  box-shadow: 0px 1px 4px $shadow;
  z-index: 1000 !important;
  .landing-navigation-bar-container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    max-width: 2000px;
    margin: 0px auto;
    padding: 0px 40px;

    @media only screen and (max-width: $mobile_breakpoints) {
      padding: 0px 20px;
    }
    @media only screen and (max-width: 400px) {
      padding: 0px 10px;
    }
  }
  .landing-logo {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    color: $primary-color-dark;
    gap: 10%;
    font-weight: 500;

    img {
      width: 100px;
      height: 30px;
      cursor: pointer;
    }

    .landing-burger {
      @media screen and (min-width: $mobile_overbreakpoints) {
        display: none;
      }
    }
  }
  .nav-links {
    width: 50%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    gap: 5%;
    @media screen and (max-width: $mobile_overbreakpoints) {
      display: none;
    }
  }

  .side-items {
    display: flex;
    flex-flow: row nowrap;
    gap: 3%;
    align-items: center;
    .items-seperator {
      height: 20px;
      width: 20px;
      background-color: #fff;
      margin: 0 3%;
    }

    .side-items-text {
      font-weight: 400 !important;
      @media screen and (max-width: 1400px) {
        @include font-size25();
      }
    }
  }
}
.navigations-bar-outlet {
  height: calc(
    100vh - 105px
  ); //remove height of navigation bar and height of message
}

.portal-component {
  background: $white;

  width: 90%;
  margin: 40px auto; //because there is the message at the top

  &.sideOpen {
    padding-left: 300px;
    width: 96%;
    margin: 30px 0px;
    @media only screen and (max-width: 800px) {
      padding: 0;
    }
  }
}
.authentication-component {
  background: linear-gradient(172deg, #0052a2, rgba(88, 104, 240, 0) 68.45%);
}

.landing-background {
  background: linear-gradient(172deg, #0052a2, rgba(88, 104, 240, 0) 68.45%);
}

.landing-footer-component {
  display: flex;
  align-items: center;
  flex-flow: column nowrap;

  background: $primary-color-dark !important;
  box-shadow: 0px 1px 4px $shadow;

  .landing-footer-container {
    @media only screen and (max-width: 599px) {
      display: none;
    }
    width: 80%;
    margin: 20px auto;
    margin-top: 50px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;

    @media screen and (max-width: 600px) {
      flex-flow: column nowrap;
    }

    .footer-element {
      color: $white;
      display: flex;

      // width: 25%;
      flex-flow: column nowrap;

      @media screen and (max-width: 600px) {
        width: 100%;
      }
      img {
        width: 100px;
        height: 30px;
        cursor: pointer;
      }

      .cursor {
        cursor: pointer;
      }

      .title {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        line-height: 15px;
        align-items: flex-start;
        @include font-size25();
        // margin-bottom: 30px;
        height: 30px;

        @media screen and (max-width: 600px) {
          margin-top: 10px;
          margin-bottom: 0px;
        }
      }

      .support-element {
        justify-content: center !important;

        @media screen and (max-width: $mobile_breakpoints) {
          justify-content: flex-start !important;
        }
      }

      .body {
        @include font-size20();
        width: 100%;
        text-overflow: ellipsis;
        overflow-x: hidden;
        white-space: nowrap;

        a {
          color: white;
        }
      }
      .social-media {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 2%;
        gap: 7%;

        @media screen and (max-width: 600px) {
          justify-content: unset;
        }
      }

      .support-footer-element {
        display: block;
        width: fit-content;
        justify-content: center;
        align-self: center;

        @media screen and (max-width: 600px) {
          justify-content: unset;
          align-self: unset;
        }
      }
    }

    .social-media-element {
      align-items: center;

      @media screen and (max-width: 600px) {
        align-items: unset;
      }
    }
  }
  .divider-section {
    @media only screen and (max-width: 599px) {
      display: none;
    }
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 80%;
    margin: 0px auto;
  }
  .landing-page-footer-divider {
    @media only screen and (max-width: 599px) {
      display: none;
    }
    .MuiDivider-root {
      color: $white !important;
      background-color: $white !important;
      background: $white !important;
    }
  }
  .footer-footer-container {
    margin: 20px auto;
    // width: 95%;
    @include font-size16();
    color: white;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 600px) {
      flex-flow: column-reverse nowrap;
      justify-content: center;
    }

    .terms-and-conditions {
      margin-bottom: 5px;
      width: 80%;
      display: flex;
      justify-content: flex-end;
      flex-flow: row nowrap;
      gap: 3%;
      align-items: center;
      @media screen and (max-width: 600px) {
        justify-content: center;
      }
    }
  }
}

.attention-note {
  display: flex;
  align-items: center;

  height: 30px;
  background: $medium-grey !important;
  color: $primary-color-dark;
  position: fixed;
  top: 65px;
  width: 100%;
  padding: 0px 10px;
  z-index: 1000 !important;

  &.sideBarOpen {
    margin-left: 200px !important;
    padding-left: 300px !important;
    width: calc(100% - 200px) !important;
  }

  .message {
    flex: 1 1 0px;
    display: flex;
    flex-flow: row nowrap;
    position: absolute;
    align-items: center;
    animation: scrollText 50s infinite linear;

    @include md-screen {
      white-space: nowrap;
      animation: scrollTextMdScreen 40s linear infinite !important;
    }

    a {
      margin-left: 2px;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  @keyframes scrollText {
    0% {
      left: 0;
    }
    50% {
      left: calc(100% - 600px);
    }
    100% {
      left: 0;
    }
  }
  @keyframes scrollTextMdScreen {
    0% {
      left: 100%;
    }
    100% {
      left: -100%;
    }
  }

  .message:hover {
    animation-play-state: paused;
  }
}

.landing-footer-container-responsive {
  @media only screen and (min-width: 599px) {
    display: none;
  }
  .footer-element {
    color: $white;
    display: flex;

    width: 25%;
    justify-content: center;
    margin-top: 10%;

    @media screen and (max-width: 600px) {
      width: 100%;
    }

    img {
      width: 100px;
      height: 30px;
      cursor: pointer;
    }

    .title {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      @include font-size25();
      // margin-bottom: 30px;
      height: 30px;
    }

    .support-element {
      justify-content: center !important;

      @media screen and (max-width: $mobile_breakpoints) {
        justify-content: flex-start !important;
      }
    }

    .body {
      @include font-size20();
      width: 100%;
      text-overflow: ellipsis;
      overflow-x: hidden;
      white-space: nowrap;

      a {
        color: white;
      }
    }
    .social-media {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 2%;
      gap: 7%;
    }

    .support-footer-element {
      display: flex;
      align-items: center;
      justify-content: center;

      .title {
        height: 26px !important;
      }
      .footer-divider {
        height: 20px;
        width: 1px;
        background-color: #ccc;
        margin: 0 10px;
      }
    }
  }
}

.icons {
  @media only screen and (min-width: 599px) {
    display: none;
  }
  margin-top: 10%;
  width: "100%";
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 7%;
}

.divider-section-responsive {
  @media only screen and (min-width: 599px) {
    display: none;
  }
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: 45%;
  margin: 0px auto;
}
.landing-list {
  padding-top: 0 !important;
  background-color: $white !important;

  .kkk:hover {
    background-color: red;
  }
}

.layout-list {
  padding-top: 0 !important;
  background-color: $primary-color-light !important;

  .logout-sidebutton {
    padding: 10px 0;
  }
}
