@import "/src/assets/theme/color.scss";
@import "/src/assets/theme/variables.scss";
@import "/src/assets/theme/media-queries.scss";
@import "/src/assets/theme/font-sizes.scss";

.plan-card-component {
  width: 100%;
  box-shadow: none !important;
  border-radius: 20px !important;
  background-color: $plan-card-bg !important;

  &.pricing-layout {
    box-shadow: $login-shadow !important;
    background-color: $login-bg !important;
  }

  .plan-card-content {
    color: $primary-color-dark;
    .centered-element {
      display: flex;
      gap: 5%;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;

      .trial-section {
        border-radius: 5px;
        border: 1px solid $primary-color-dark;
        padding: 2px;
      }
      .description-section {
        word-break: break-all;
      }
    }
    .plan-component-seperator {
      width: 70%;
      border: 1px solid $primary-color-dark-40;
    }
    .plan-package-price {
      width: 100%;
      color: $primary-color-dark;
      font-weight: 700;
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;

      // @include md-screen {
      //   flex-flow: column nowrap !important;
      // }
      .plan-package-seperator {
        justify-content: center;
        display: flex;
        width: 10px;
      }

      .price-element {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        // width: 35%;

        .price-currency {
          @include font-size35();
        }
        .duration {
          @include font-size18();
        }
      }
    }

    .plan-component-items {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      width: 100%;
      text-align: left;

      .plan-item {
        display: flex;
        flex-flow: row;
        align-items: flex-start;
        gap: 1%;
        width: 80%;
        margin: 0px auto;

        .check-icon {
          margin-top: 1px;
        }
      }
    }
    .plan-actions {
      width: 70%;
      margin: 20px auto 10px auto;
    }
    .no-credit-card-message {
      @include font-size16();
      font-weight: 700;
    }
  }
}
