@mixin xlg-screen {
  @media (min-width: 1501px) {
    @content;
  }
}

@mixin lg-screen {
  @media (max-width: 1500px) {
    @content;
  }
}

@mixin md-screen {
  @media (max-width: 900px) {
    @content;
  }
}

@mixin sm-screen {
  @media (max-width: 600px) {
    @content;
  }
}

@mixin xsm-screen {
  @media (max-width: 300px) {
    @content;
  }
}
