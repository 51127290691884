@import "/src/assets/theme/color.scss";
@import "/src/assets/theme/variables.scss";
@import "/src/assets/theme/font-sizes.scss";

@font-face {
  font-family: "Mulish";
  src: url("./assets/fonts/Mulish-Regular.ttf") format("ttf");
}

body {
  background-color: $body-color;
  padding-top: 60px;
  margin: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  outline: none;
}

.page-container {
  padding: 30px 0px;
  width: 100%;
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cursor-pointer {
  cursor: pointer;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: $primary-color-dark !important;
  border-radius: 25px;
}

::-webkit-scrollbar-thumb:hover {
  background: $primary-color-dark;
}
.text-underline {
  // text-decoration: underline !important;
  color: $white !important;
  font-weight: 100 !important;
  @media screen and (max-width: 1400px) {
    @include font-size25();
  }
}
.link-selected {
  color: $white !important;
  font-weight: 800 !important;
  @media screen and (max-width: 1400px) {
    @include font-size25();
  }
}

.margin-up-20 {
  margin: 20px 0px;
}
.no-margin {
  margin: 0px !important;
}
.form-footer-style {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  // margin-left: auto;
  margin-top: 10px;
  justify-content: flex-end;

  @media screen and (max-width: 400px) {
    margin: auto;
    padding-bottom: 10px;
  }
}

.form-footer-elements {
  display: flex;
  flex-flow: row nowrap;
  gap: 10%;
}
.message-space {
  text-align: center;
  margin: 10px;

  .message-content {
    @include font-size16();
    font-style: italic;
    color: red;
  }
}
.total-data-counts {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  @include font-size20();
  margin-bottom: 10px;
  color: $secondary-color;
}

.absolute-position {
  position: absolute;
}

.form-inputs-double-children {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px 40px;

  @include sm-screen {
    grid-template-columns: 1fr;
  }
}

.highlighted-text {
  font-weight: bold;
  color: $primary-color-dark;
}

.centered-text {
  display: flex;
  justify-content: center;
  text-align: center;
}

.modal-button-action {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 5%;
  .save-button {
    width: 180px;
    @include font-size18();
  }
}
.text-justify {
  text-align: justify;
}
.long-tablecell-description {
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
}
