@import "/src/assets/theme/color.scss";
@import "/src/assets/theme/variables.scss";
@import "/src/assets/theme/media-queries.scss";
@import "/src/assets/theme/font-sizes.scss";

.card-management-component {
  width: 100%;
  margin-top: 3%;
  padding: 0 0 3% 0;

  .card-management-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.only-add {
      justify-content: flex-end !important;
    }
  }

  .card-management-radio-group {
    padding: 10px 5px;
    display: flex;
    gap: 20px;
    margin-top: 3%;
    flex-direction: column;
    max-height: 500px;
    overflow-y: auto;
    .card-management-element {
      display: flex;
      gap: 10px;

      .radio-button {
        width: 25px;
      }
      .card-detail-section {
        display: flex;
        width: 100%;
        border-radius: 14px;
        padding: 20px;
        justify-content: space-between;
        align-items: center;
        box-shadow: $radio-button-shadow;
        @include sm-screen {
          flex-flow: column nowrap;
          align-items: flex-start;
        }

        .action-section {
          display: flex;
          gap: 5px;
          align-items: center;
          max-width: 150px;

          .disabled-icon {
            opacity: 0.5;
          }
          @include sm-screen {
            justify-content: flex-end;
            max-width: unset;
            gap: 1%;
            width: 100% !important;
          }

          .default {
            @include font-size16();
            text-decoration: underline;
            color: $primary-color-dark;
            cursor: pointer;
            width: 100px;
          }
        }
        .radio-default-end {
          justify-content: flex-end !important;
        }
        .name {
          @include font-size25();
          color: $primary-color-dark;
          font-weight: 600;
          letter-spacing: 0.2px;
        }
        .not-saved {
          display: flex;
          align-items: center;
          gap: 30px;
          .icon {
            height: 25px;
            display: flex;
          }
        }
      }
    }
  }
}
