@import "/src/assets/theme/media-queries.scss";

$border-radius: 20px;
$authentication-border-radius: 59px;
$heading-font-size: "50px";
$mobile_breakpoints: "900px";
$mobile_overbreakpoints: "901px";
$mobile_landing_breakpoints: "700px";
$mobile_landing_overbreakpoints: "701px";
$shadow: 2px 4px 4px 0px #000000;
$table-shadow: 2px 2px 10px 0px #1c263d66;

:export {
  shadowElement: $shadow;
  tableShadow: $table-shadow;
  mobileBreakPoints: $mobile_breakpoints;
}
